.App header {
  z-index: 5;
  position: relative;
}

.App h1 {
  padding: 1.5em 0 1em;
  font-size: clamp(1.3em, 4vw, 2.2em);
}

.App {
  background: url(https://www.glotoncomida.com/imagenes/home/background_pattern.png);
  height: auto;
  min-height: 100%;
}
