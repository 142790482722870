.MuiAppBar-root {
  padding: 1em;
}

header .logo a {
  color: #fff;
  text-decoration: none;
  font-size: 2em;
  font-size: clamp(1.2em, 4vw, 2em);
  white-space: nowrap;
}

header button a {
  font-size: 0;
}

header .logo {
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

header .actions-container {
  float: right;
  color: #fff;
}

@media (max-width: 900px) {
  header .actions-container {
    position: fixed;
    bottom: 1em;
    right: 1em;
  }
}
