.main-content {
  background: url(https://www.glotoncomida.com/imagenes/home/background1.jpg);
  background-size: cover;
  position: relative;
}

.home h1 {
  color: white;
  text-shadow: 4px 2px 6px #000;
}

.info-content img {
  width: 10em;
}
